import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import { Headers, Main, ParsWrapper } from "../components/typography"

const Newsletter = ({ data }) => {
  const titleArray = data.sanityNewsLetterPost.sectionDesc
  const pdfArray = data.sanityNewsLetterPost.sectionDesc
  const pdfTitleArray = data.sanityNewsLetterPost.sectionDesc

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }
  return (
    <Layout>
      <Main>
        {data.sanityNewsLetterPost._rawSectionDesc.map((block, i) => {
          return (
            <>
              {!block.hide && (
                <>
                  <Headers>{titleArray[i]["title"]}</Headers>
                  <ParsWrapper>
                    <BlockContent
                      blocks={block.content}
                      serializers={serializers}
                    />
                  </ParsWrapper>

                  {pdfArray[i]["pdfUpload"] && (
                    <a href={pdfArray[i]["pdfUpload"]["asset"]["url"]}>
                      {pdfTitleArray[i]["pdfTitle"]}
                    </a>
                  )}
                </>
              )}
            </>
          )
        })}
      </Main>
    </Layout>
  )
}

export default Newsletter

export const query = graphql`
  query NewsletterQuery {
    sanityNewsLetterPost {
      _rawSectionDesc(resolveReferences: { maxDepth: 10 })
      sectionDesc {
        title
        pdfTitle
        hide
      }
    }
  }
`
